
import Home from './pages/Home';
import About from './pages/About';
import Add from './pages/Add';
import Login from './pages/Login';

import { auth } from './services/firebase';
import { useState, useEffect } from 'react';

import './assets/styles/app.scss';

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";



function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === false
        ? <Component {...props} />
        : <Redirect to='/add' />}
    />
  )
}



function App() {
  const [ loading, setLoading ] = useState(true);
  const [ authenticated, setAuthenticated ] = useState(false);
  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true);
        setLoading(false);
      } else {
        setAuthenticated(false);
        setLoading(false);
      }
    })

  })
  return loading ? <h2>Loading</h2> : (
    <Router>
      <Switch>
        <Route exact path="/" component={ Home }></Route>
        <Route exact path="/about" component={ About }></Route>
        <PrivateRoute path="/add" authenticated={ authenticated } component={ Add }></PrivateRoute>
        <PublicRoute path="/login" authenticated={ authenticated } component={ Login }></PublicRoute>
      </Switch>
    </Router>
  )
}

export default App;
