import React, { useEffect, useState } from 'react';
import { auth, db } from "../services/firebase"
import firebase from "firebase/app";
import 'firebase/storage';
import Header from "../components/Header";
import Footer from "../components/Footer";

import '../assets/styles/listing.scss';

function Home (){
  var storage = firebase.storage();
  var storageRef = storage.ref('images');

  const [ posts, setPosts ] = useState([]);
  const [ user ] = useState(auth().currentUser);
  const [ readError, setReadError] = useState(null);
  const [ deleteItem, setDeleteItem ] = useState(null);

  const deleteInitial = (id) => {
    setDeleteItem(id);
  }
  const deleteDoc = (id, arrayIndex) => {
    db.collection("posts").doc(id).delete().then(function(){
      const deletedPost = posts[arrayIndex];
      posts.splice(arrayIndex, 1);
      setPosts(posts);
      deletedPost.files.map((file, i) => {
        if (file.filename){
          var ref = storageRef.child(user.uid).child(file.filename);
          ref.delete()
        }
      })
    })
  }
  useEffect(() => {
    try {
      db.collection("posts").orderBy('timestamp', 'desc').onSnapshot(snapshot => {
        let posts = [];
        snapshot.forEach((snap) => {
          let post = snap.data();
          post.id = snap.id;
          posts.push(post);
        })
        setPosts(posts);
      })
    }catch(error){
      setReadError(error.message);
    }
  }, [])
  return (
    <div>
      <Header/>
      <main className="container listing">
        { readError && <span className="message error">{ readError }</span>}
        { posts.map((post, i) => (
          <div key={ i } className="post">
            <ul className="actions">
              { user && user.uid === post.uid && deleteItem !== post.id && <li><button onClick={ (e) => deleteInitial(post.id) } className="icon action error">delete this post</button></li>}
              { user && user.uid === post.uid && deleteItem === post.id && (
              <li>
                Are you sure?
                <ul>
                  <li><button onClick={ (e) => deleteDoc(post.id, i) } className="icon action error">Go for it.</button></li>
                  <li><button onClick={ (e) => deleteInitial(null) } className="icon action error">Never mind.</button></li>
                </ul>
               </li>
              )}
            </ul>
            { post.text && <p>{ post.text }</p>}
            { post.files && post.files.map((file, i) => {

              return <img alt="" className="image" src={ file.url || file } key={ i }></img>
            })}
          </div>
        ))}
      </main>
      <Footer/>
    </div>
  )
}

export default Home;
