import { Link } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

function About (){
  return (
    <div>
      <Header/>
      <main>
        <div className="container">
          <h1>What is this?</h1>
          <div className="cols">
            <p>I believe that making art is something that we should all be doing, not just folks who feel comfortable with labeling themselves as artists.</p>
            <p>That's why this is called ArtCo for art collective and not artist collective. (I also like the idea of co being shorthand for community because that's what I want this to feel like.)</p>
            <p>I also wanted to make a platform where the focus is on creation and inspiration from others - and trying to move away from validation, which is why the posts are displayed anonymously and there aren't any feedback interactions.</p>
            <p>So you may ask yourself, why would I want to participate if I'm not getting any feedback? My hope is that seeing others create inspires you to also create - and that one of the basic human needs, of being seen, is fulfilled even if you aren't getting recognition.</p>
            <p>I wanted to create a space that allowed for mistakes, exploration, trying new things without feelings of intimidation that may be associated with publishing content under your name.</p>
            <p>Really I just wanted a platform where we take away all of the BS that can prevent us from creating whatever our hearts and minds happen to want to spew out at any point in time.</p>
            <p style={ { marginTop: "25px", fontSize: "20px", lineHeight: 1.2 }}>Interested? <br /><Link to="/add">Go ahead and add your creation.</Link></p>
          </div>

        </div>

      </main>
      <Footer/>
    </div>
  )
}

export default About
