function Footer(){
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        ArtCo &copy; { currentYear }
      </div>
    </footer>
  )
}

export default Footer;
