import { Link } from 'react-router-dom';
import { auth } from '../services/firebase';

function Header(){
  const authenticated = auth().currentUser;

  return (
    <header className={ authenticated ? 'is-loggedIn' : ''}>
      <div className="container">
        <nav>
          <Link to="/" className="logo">
            ArtCo
          </Link>
          <ul className="menu">
            <li><Link to="/about">What is this?</Link></li>
            <li><Link to="/add">Post New</Link></li>
          </ul>
        </nav>
        { authenticated && <button className="signOut" onClick={e => auth().signOut()}>Sign Out</button>}

      </div>
    </header>
  )
}

export default Header;
