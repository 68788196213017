import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import 'firebase/storage';
import FileUploader from "react-firebase-file-uploader";
import { Link } from "react-router-dom";


import { auth, db } from "../services/firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";

import '../assets/styles/forms.scss';

function Add (){
  const [ content, setContent ] = useState({});
  const [ isUploading, setIsUploading] = useState(false);
  const [ progress, setProgress] = useState(0);
  const [ files, setFiles ] = useState([]);
  const [ writeError, setWriteError] = useState(null);
  const [ user ] = useState(auth().currentUser);
  const [ success, setSuccess] = useState(false);

  var storage = firebase.storage();
  var storageRef = storage.ref('images');

  async function handleSubmit(e){
    e.preventDefault();
    try {
      await db.collection("posts").add({
        ...content,
        timestamp: Date.now(),
        uid: user.uid,
        files
      })
      setSuccess(true);
    }catch(error){
      setWriteError(error.message);
    }

  }
  const startOver = () => {
    setFiles([])
    files.map((file, i) => {
      var ref = storageRef.child(user.uid).child(file.filename);
      ref.delete()
    })
  }
  const handleUploadStart = () => {
    setFiles(files)
    setIsUploading(true);
    setProgress(0);
  }
  const handleProgress = progress => setProgress(progress);
  const handleUploadError = error => {
    setIsUploading(false);
  };
  const handleUploadSuccess = filename => {
    setProgress(100);
    setIsUploading(false);
    let filesArray = files;

    firebase
      .storage()
      .ref("images")
      .child(user.uid)
      .child(filename).getDownloadURL().then(url => {
        filesArray.push({
          filename,
          url
        });
        setFiles([...filesArray]);
      })

  };



  function handleChange(e){
    const { name, value } = e.target;
    let newContent = Object.assign(content, {});
    newContent[name] = value;
    setContent(newContent);
  }


  console.log(files);

  return (
    <div>
      <Header/>
      <main className="container upload">
        <div className="intro">
          <h1>Well, now what?</h1>
          <p>Now you just write that song or poem that you have had living in your head or draw your favorite memory and then add it here.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input">
            <label htmlFor="post_text">Text</label>
            <span className="instructions">
              (Poem, short story, rap, description of images below)
            </span>
            <textarea id="post_text" name="text" onChange={ e => handleChange(e) }></textarea>
          </div>
          <div className="input">
            <label htmlFor="post_images">Images</label>
            <span className="instructions">Can upload multiple</span>
            <div style={{ marginBottom: '20px'}}>
              { files && files.map((file, i) => {
                return <img style={ { marginRight: '20px' }} alt={ file.filename } className="image" src={ file.url } key={ i }/>
              })}
            </div>
            { files.length === 0 ?
            <FileUploader
                  accept="image/*"
                  name="files"
                  id="post_images"
                  randomizeFilename
                  storageRef={firebase.storage().ref("images").child(user.uid)}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSuccess}
                  onProgress={handleProgress}
                  multiple
                /> : <button style={ { marginBottom: '12px'}} onClick={ e => startOver()}>Re-upload images</button>}
            { isUploading &&
              <div className="progress">
                <div className="progress-bar" style={{ width: progress + "%"}}></div>
              </div>
            }

          </div>
          { writeError && <span className="message error">{ writeError }</span> }
          { success ? <span className="message success">Posted! <Link to="/">Go check it out.</Link></span> :
            <p>{ isUploading ? <span>Uploading...</span> : <button type="submit">Post</button>}</p>
          }


        </form>
      </main>
      <Footer/>
    </div>

  )
}

export default Add;
