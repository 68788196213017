import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyD32urLlCsB6W5TqSvHaQQX0691Bk-lwF8",
  authDomain: "artco-654c3.firebaseapp.com",
  databaseURL: "https://artco-654c3.firebaseio.com",
  projectId: "artco-654c3",
  storageBucket: "artco-654c3.appspot.com",
  messagingSenderId: "411534678393",
  appId: "1:411534678393:web:5e6651d00bb9bd633437a8",
  measurementId: "G-S1VVY4E3WY"
};

firebase.initializeApp(config);
export const auth = firebase.auth;
export const db = firebase.firestore();
