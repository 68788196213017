import React, { useState } from 'react';
import { signInWithGoogle } from '../helpers/auth';
import Header from "../components/Header";
import Footer from "../components/Footer";

import '../assets/styles/forms.scss';

function Login (){
  const [ error, setError ] = useState(false);
  const googleSignIn = async function() {
    try {
      await signInWithGoogle();
    } catch (error) {
      setError(error.message);
    }
  }

  return (
    <div>
      <Header/>
      <main className="container">
        <h1>Login to be able to add your creation</h1>
        <p>Currently only using Google authentication. <a href="mailto:hello@kristinefernandez.com">Please email me if this isn't going to work for you.</a>
          <br />
          { error && <span className="message error">{ error }</span>}
          <button onClick={googleSignIn} type="button" className="button">
            Login with Google
          </button>
        </p>


      </main>
      <Footer/>
    </div>
  )
}

export default Login;
